<template>
    <div>
        <b-container fluid>
            <b-table
                show-empty
                small
                stacked="md"
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filterIncludedFields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                >
                <template v-slot:cell(actions)="row">
                    <b-button size="sm" @click="info(row.item, row.index, $event.target)">詳細</b-button>
                    <!-- <b-button size="sm" @click="row.toggleDetails">刪除</b-button> -->
                </template>    
            </b-table>

            <!-- Info modal -->
            <b-modal :id="infoModal.id" :title="infoModal.title" ok-only>
                <b-row class="my-1">
                    <b-col sm="9">
                        名稱: {{infoModal.name}}{{infoModal.sex}}
                    </b-col>
                </b-row>

                <b-row class="my-1">
                    <b-col sm="9">
                        聯絡方式: {{infoModal.tel}}
                    </b-col>
                </b-row>

<!--                <b-row class="my-1">-->
<!--                    <b-col sm="9">-->
<!--                        電子信箱: {{infoModal.email}}-->
<!--                    </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="my-1">-->
<!--                    <b-col sm="9">-->
<!--                        Line ID: {{infoModal.line}}-->
<!--                    </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="my-1">-->
<!--                    <b-col sm="9">-->
<!--                        信件內容: {{infoModal.content}}-->
<!--                    </b-col>-->
<!--                </b-row>-->

<!--                <b-row class="my-1">-->
<!--                    <b-col sm="9">-->
<!--                        詢問貸款類別:-->
<!--                    </b-col>-->
<!--                </b-row>-->

                <b-row class="my-1">
                    <b-col sm="9">
                        聯絡時間: {{infoModal.create_datetime}}
                    </b-col>
                </b-row>
            </b-modal>

        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [],
            fields:[
                { key: 'id', label: '編號', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: '名稱'},
                // { key: 'sex', label: '性別'},
                { key: 'tel', label: '聯絡方式'},
                // { key: 'email', label: '電子信箱'},
                // { key: 'line', label: 'Line ID'},
                // { key: 'content', label: '信件內容'},
                { key: 'create_datetime', label: '聯絡時間'},
                // { key: 'actions', label: '編輯' }
            ],
            currentPage: 1,
            perPage: 5,
            filter: null,
            filterOn: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            infoModal: {
                id: '',
                name: '',
                // sex: '',
                tel: '',
                // email: '',
                // line: '',
                // content: '',
                create_datetime: '',
            }
        }
    },

    async created() {
        let response = await this.axios.post(this.GLOBAL.serverSrc + "contact.php", {
                username: sessionStorage.getItem('account'),
                token: sessionStorage.getItem('token'),
                action: "list"
        });

        if (response.data.status === '1') {
            for (let index = 0; index < response.data.data.length; index++) {
                this.items.push(response.data.data[index])
            }
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        info(item, index, button) {
            this.infoModal.title = `編號: ${item.id}`;
            this.infoModal.id = item.id;
            this.infoModal.name = item.name;
            // this.infoModal.sex = item.sex
            this.infoModal.tel = item.tel;
            // this.infoModal.email = item.email
            // this.infoModal.line = item.line
            // this.infoModal.content = item.content
            this.infoModal.create_datetime = item.create_datetime;

            this.$root.$emit('bv::show::modal', this.infoModal.id, button);
        },
    }
}
</script>